<template>
  <DashboardContainer :sidebarItems="sidebarItems">
    <p>This NPO's ID is {{ id }} {{ name }}</p>
  </DashboardContainer>
</template>

<script setup>
import DashboardContainer from '../components/DashboardContainer.vue'
import { BuildingLibraryIcon, MegaphoneIcon } from '@heroicons/vue/20/solid'

import { defineProps } from 'vue'

const props = defineProps({
  id: String,
  name: String,
})

const sidebarItems = [
  {
    name: 'NPOs',
    url: '/npos',
    icon: BuildingLibraryIcon,
    current: true,
  },
  {
    name: 'Campaigns',
    url: '/campaigns',
    icon: MegaphoneIcon,
    current: false,
  },
]
</script>
